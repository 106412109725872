import React from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

const Services = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Services" />
      <Image
        fluid={data.mast.childImageSharp.fluid}
        alt="Me"
        style={{
          maxWidth: `100%`,
          marginBottom: rhythm(1 / 2),
          marginLeft: `auto`,
          marginRight: `auto`,
          marginTop: 0,
        }}
      />
      <div style={{ margin: `auto`, maxWidth: rhythm(34) }}>
        <article>
          <header>
            <div
              style={{
                marginBottom: rhythm(1 / 4),
                textAlign: `center`,
              }}
            >
              <Link
                style={{
                  display: `block`,
                  margin: `2rem 0`,
                  boxShadow: `none`,
                }}
                to="why-video"
              >
                Why use video?
              </Link>
              <Link
                style={{
                  display: `block`,
                  margin: `2rem 0`,
                  boxShadow: `none`,
                }}
                to="video-production"
              >
                Video Production Services
              </Link>
              <Link
                style={{
                  display: `block`,
                  margin: `2rem 0`,
                  boxShadow: `none`,
                }}
                to="video-content"
              >
                Let's Get Visual
              </Link>
              <Link
                style={{
                  display: `block`,
                  margin: `2rem 0`,
                  boxShadow: `none`,
                }}
                to="cost"
              >
                How much will my video cost?
              </Link>
            </div>
          </header>
        </article>
      </div>
    </Layout>
  )
}

export default Services

export const pageQuery = graphql`
  query {
    mast: file(absolutePath: { regex: "/mast-services.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
